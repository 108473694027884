<template>
  <div ref="topDiv">
    <confirm-delete
      ref="modal"
      :msg="deleteMsg"
      :title="titleMsg"
      @on-submit="deletePromotion"
    />

    <!-- Duplicate offer confirm modal -->
   <confirm-disable
      ref="disableModal"
      :msg="disableMsg"
      @on-submit="switchPromotionStatus"
      @on-hide="resetStatus"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="10"
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col
            cols="2"
            md="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button class="mr-2 mb-2 float-right" variant="primary" @click="$router.push('add')">
              <span class="text-nowrap">Add Promotion</span>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-table
        v-if="!contentLoading"
        ref="refUserListTable"
        class="position-relative offer_table_cstm"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(image)="data">
          <img :src="data.item.image" v-bind="imgSize" @error="defaulImage" />
        </template>

        <template #cell(start_date)="data">
          <p style="width: 100px;">{{ data.item.startDate | formatDate }}</p>
        </template>
        <template #cell(end_date)="data">
          <p style="width: 100px;">{{ data.item.endDate | formatDate }}</p>
        </template>

        <template #cell(status)="data">
          <div>
            <b-form-checkbox
              v-model="data.item.status"
              class="custom-control-primary"
              name="check-button"
              switch
              @change="
                openDisableModal(
                  data.item.id,
                  data.item.status,
                  data.item.title
                )
              "
            />
          </div>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="
                checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.Edit
              "
              :to="{ name: 'promotion-banner-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
           
            <b-dropdown-item
              v-if="
                ((checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.delete) )
              "
              @click="openModal(data.item.id, data.item.title)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <Loader v-if="contentLoading" />
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">Showing {{ totalPromotions >= 1 ? startIndex + 1 : startIndex }} to
              {{ endIndex > totalPromotions ? totalPromotions : endIndex }} of
              {{ totalPromotions }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalPromotions"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="pageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BFormCheckbox,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  perPageOptions,
  perPage,
  currentPage,
  RoleEnum,
  resConditionCheck,
  deleteConfirmMsg
} from "@/helpers/constant";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import checkLoginRole from "@/helpers/checkLoginRole";
import promotionService from "@/services/promotion/promotion.service";
import accessRightCheck from "@/helpers/accessRightCheck";
import ConfirmDelete from "@/views/confirm-delete/ConfirmDelete.vue";
import ConfirmDisable from "@/views/confirm-disable/ConfirmDisable.vue";
import Loader from "@/layouts/skeloton-loader/Loader.vue";
import BeonTripsLogo from "@/assets/images/logo/beontrips.png";

export default {
  name: "PromotionBannerList",
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BCardHeader,
    BCardBody,
    ConfirmDelete,
    Loader,
    BFormCheckbox,
    ConfirmDisable,
    BButton,
  },

  data() {
    return {
      contentLoading: true,
      sortBy: "",
      perPageOptions,
      perPage,
      totalPromotions: 0,
      isSortDirDesc: true,
      currentPage,
      items: [],
      disableMsg: "",
      deleteMsg: "",
      RoleEnum,
      titleMsg: "",
      startIndex: 0,
      endIndex: 0,
      errorData: "",
      promotionId: "",
      status2: "",
      checkLoginRole,
      checkAccess: {},
      tableColumns: [
        { key: "image" },
        { key: "id", sortable: true },
        { key: "title" },
        { key: "start_date" },
        { key: "end_date" },
        { key: "status" },
        { key: "actions" }
      ],
      imgSize: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1"
      },
    };
  },

  watch: {
    currentPage: {
      handler() {
        this.getPromotionListing();
      }
    },

    perPage: {
      handler() {
        this.currentPage = 1;
        this.getPromotionListing();
      }
    },

    sortBy: {
      handler(newValue) {
        console.log("Sort by", newValue)
        // this.sortBy = newValue === "priority" ? "priorityNumber" : newValue;
        this.getPromotionListing();
      }
    },

    isSortDirDesc: {
      handler() {
        this.getPromotionListing();
      }
    },
  },

  async mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    if (
      this.checkLoginRole() !== RoleEnum.SuperAdmin
      && !this.checkAccess.Edit && !this.checkAccess.delete
    ) {
      this.tableColumns = [
        { key: "image" },
        { key: "id", sortable: true },
        { key: "title" },
        { key: "start_date" },
        { key: "end_date" },
        { key: "status" },
      ];
    }
    await this.getPromotionListing();
  },

  methods: {
    pageChange() {
      window.scrollTo(0, 0);
    },

    defaulImage(e) {
      e.target.src = BeonTripsLogo;
    },

    async getPromotionListing() {
      this.contentLoading = true
      const payload = {
        no_of_result: this.perPage,
        page: this.currentPage,
        order: this.isSortDirDesc === true ? "DESC" : "ASC",
        order_by: this.sortBy
      };
      await promotionService.getPromotionListing(payload)
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.contentLoading = false;
            this.items = res.data.data && res.data.data.length ? res.data.data.map(item => {
              return {
                ...item,
                title: item.title ? item.title : 'NA',
                status: item.status === 1 ? true : false
              }
            }) : res.data.data;
            this.totalPromotions = res.data.count;
            this.startIndex = this.currentPage * this.perPage - this.perPage;
            this.endIndex = this.startIndex + this.perPage;
          }
        })
        .catch(error => {
          const errorData = errorResponseHandler(error);
          this.contentLoading = false;
          if (error.response.status === 404) {
            this.items = [];
            this.totalPromotions = 0;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          }
        });
    },

    openDisableModal(id, status, name) {
      this.promotionId = id;
      this.status2 = !status;
      this.$refs.disableModal.show();
      this.disableMsg = !status
        ? `Are you sure you want to deactivate ${name} promotion ?`
        : `Are you sure you want to activate ${name} promotion ?`;
    },
    resetStatus() {
      const foundIdex = this.items.findIndex(
        item => item.id === this.promotionId
      );
      this.$set(this.items[foundIdex], "status", this.status2);
    },

    async switchPromotionStatus(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.disableModal.loading = true;
      if (this.promotionId) {
        await promotionService.activeInactivePromotion(this.promotionId)
          .then(res => {
            this.$refs.disableModal.loading = false;
            this.$refs.disableModal.toggleModal = false;
            if (resConditionCheck(res.status)) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            this.$refs.disableModal.loading = false;
            this.$refs.disableModal.toggleModal = false;
            const errorData = errorResponseHandler(error);
            const foundIdex = this.items.findIndex(
              item => item.id === this.promotionId
            );
            this.$set(this.items[foundIdex], "status", this.status2);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      }
    },

    openModal(promotionId, name) {
      this.titleMsg = deleteConfirmMsg;
      this.deleteMsg = `Are you sure you want to delete ${name} promotion ?`;
      this.promotionId = promotionId;
      this.$refs.modal.show();
    },

    async deletePromotion(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.modal.loading = true;
      if (this.promotionId) {
        await promotionService
          .deletePromotion(this.promotionId)
          .then(res => {
            if (resConditionCheck(res.status)) {
              this.$refs.modal.loading = false;
              this.$refs.modal.toggleModal = false;
              this.items = this.items.filter(item => item.id !== this.promotionId);
              if (this.items.length === 0 && this.currentPage !== 1) {
                this.currentPage -= 1;
                this.getPromotionListing();
              } else {
                this.getPromotionListing();
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            this.$refs.modal.loading = false;
            this.$refs.modal.toggleModal = false;
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      }
    },

  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
